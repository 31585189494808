<!--
 * @Descripttion : 供应商管理
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-31 21:05:30
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-21 15:33:54
-->

<template>
    <div>
        <div style="padding:10px 0;">
            <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                <a-form-model-item label="是否禁用">
                    <a-select v-model="formInline.isDisable" style="width:70px;" @change="getSupplierList">
                        <a-select-option value="">
                            全部
                        </a-select-option>
                        <a-select-option :value="0">
                            否
                        </a-select-option>
                        <a-select-option :value="1">
                            是
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item>
                    <a-input-search v-model="formInline.keyWord" placeholder="名称/联系人/联系电话" allowClear enter-button @search="getSupplierList" />
                </a-form-model-item>
                <a-form-model-item v-if="permissions.indexOf('supplier:add')>-1">
                    <a-button type="primary" icon="plus" @click="addSupplier" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">
            <template slot="operationContact" slot-scope="record">
                <!-- {{record.provinceName}} -->
                {{record.cityName}}
                <!-- {{record.areaName}} -->
            </template>

            <template slot="serviceType" slot-scope="record">
                {{record.serviceType?(record.serviceType==1?'内部服务':'公开服务'):'-'}}
            </template>

            <template slot="performancePayment" slot-scope="record">
                <div v-if="record.serviceType&&record.serviceType==2">{{record.performancePayment}}元</div>
                <div v-else>-</div>
            </template>

            <template slot="transferFee" slot-scope="record">
                <div v-if="record.serviceType&&record.serviceType==2">{{record.transferFee}}元 / {{record.filingFee}}元</div>
                <div v-else>-</div>
            </template>

            <template slot="serviceChargeRatio" slot-scope="record">
                <div v-if="record.serviceType&&record.serviceType==2">{{record.serviceChargeRatio}}%</div>
                <div v-else>-</div>
            </template>

            <template slot="isDisable" slot-scope="record">
                {{record.isDisable==1?'是':'否'}}
            </template>

            <template slot="operation" slot-scope="record">
                <a-space>
                    <a-button type="primary" size="small" v-if="permissions.indexOf('supplier:edit')>-1" icon="edit" @click="editSupplier(record)" />
                    <a-button type="danger" size="small" v-if="permissions.indexOf('supplier:delete')>-1" icon="delete" @click="delSupplier(record)" />
                </a-space>
            </template>
        </a-table>

        <!-- 新增/编辑供应商 -->
        <a-drawer :title="title" width="calc(100% - 200px)" :visible="visibleDrawer" @close="onClose">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="addform">
                <a-form-model-item label="供应商品牌" prop="supplierHeadquartersNumber">
                    <a-select v-model="form.supplierHeadquartersNumber" placeholder="请选择供应商品牌" style="width: 260px">
                        <template v-for="(item,index) in options">
                            <a-select-option :value="item.number" :key="index">
                                {{item.name}}
                            </a-select-option>
                        </template>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="供应商名称" prop="name">
                    <a-input v-model="form.name" placeholder="请输入供应商名称" class="f_wid" />
                </a-form-model-item>

                <a-form-model-item label="地址" prop="areaCode">
                    <selectProvinceCity :selectedProvinceCity="provinceCitySelected" @change="onChange" style="width: 260px"></selectProvinceCity>
                </a-form-model-item>

                <a-form-model-item label="详细地址" prop="detailAdress">
                    <a-input v-model="form.detailAdress" type="textarea" placeholder="请输入详细地址" style="width: 400px" />
                </a-form-model-item>

                <a-form-model-item label="供应商联系人" prop="contact">
                    <a-input v-model="form.contact" placeholder="请输入供应商联系人" class="f_wid" />
                </a-form-model-item>

                <a-form-model-item label="供应商联系电话" prop="phone">
                    <a-input v-model="form.phone" placeholder="请输入供应商联系电话" class="f_wid" />
                </a-form-model-item>

                <a-form-model-item label="自动接单" prop="formAutoOrderReceive">
                    <a-switch v-model="form.formAutoOrderReceive" checked-children="是" un-checked-children="否" />
                </a-form-model-item>

                <a-form-model-item label="是否禁用" prop="formDisable">
                    <a-switch v-model="form.formDisable" checked-children="是" un-checked-children="否" />
                </a-form-model-item>

                <a-form-model-item label="服务类型" prop="serviceType">
                    <a-radio-group v-model="form.serviceType" button-style="solid" @change="onChangeServiceType">
                        <a-radio-button :value="1">内部服务</a-radio-button>
                        <a-radio-button :value="2">公开服务</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

                <div v-if="visibleServiceInfo">
                    <a-form-model-item label="店铺照片" prop="storePhotoUrl">
                        <uploadImg ref="uploadImg" :imgConfig="fileList" @uploadRetrun="uploadImgReturn"></uploadImg>
                    </a-form-model-item>

                    <a-form-model-item label="店铺名称" prop="storeName">
                        <a-input v-model="form.storeName" placeholder="请输入店铺名称" class="f_wid" />
                    </a-form-model-item>

                    <a-form-model-item label="履约金(元)" prop="performancePayment">
                        <a-input-number v-model="form.performancePayment" placeholder="请输入数值" class="f_wid" />
                    </a-form-model-item>

                    <a-form-model-item label="服务费比例" prop="serviceChargeRatio">
                        <a-input-number v-model="form.serviceChargeRatio" placeholder="请输入数值" class="f_wid" />%
                    </a-form-model-item>

                    <a-row>
                        <a-col :span="6" :offset="6" style="padding-top:15px;font-weight:700;font-size:16px;">服务项目</a-col>
                    </a-row>

                    <a-form-model-item label="本市过户费用(元)" prop="transferFee">
                        <a-input-number v-model="form.transferFee" placeholder="请输入数值" class="f_wid" />
                    </a-form-model-item>

                    <a-form-model-item label="收费说明(300字内)" prop="transferFeeDesc">
                        <a-textarea v-model="form.transferFeeDesc" placeholder="请输入本市过户收费说明" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="办证准备(300字内)" prop="transferCertificatePreparation">
                        <a-textarea v-model="form.transferCertificatePreparation" placeholder="请输入本市过户办证准备" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="办证前提条件(300字内)" prop="transferCertificatePrecondition">
                        <a-textarea v-model="form.transferCertificatePrecondition" placeholder="请输入本市过户办证前提条件" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="其他说明(300字内)">
                        <a-textarea v-model="form.transferOtherDesc" placeholder="请输入本市过户其他说明" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="转籍提档费用(元)" prop="filingFee">
                        <a-input-number v-model="form.filingFee" placeholder="请输入数值" class="f_wid" />
                    </a-form-model-item>

                    <a-form-model-item label="收费说明(300字内)" prop="filingFeeDesc">
                        <a-textarea v-model="form.filingFeeDesc" placeholder="请输入转籍提档收费说明" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="办证准备(300字内)" prop="filingCertificatePreparation">
                        <a-textarea v-model="form.filingCertificatePreparation" placeholder="请输入转籍提档办证准备" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="办证前提条件(300字内)" prop="filingCertificatePrecondition">
                        <a-textarea v-model="form.filingCertificatePrecondition" placeholder="请输入转籍提档办证前提条件" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="其他说明(300字内)">
                        <a-textarea v-model="form.filingOtherDesc" placeholder="请输入转籍提档其他说明" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="赔付标准(300字内)" prop="compensationStandards">
                        <a-textarea v-model="form.compensationStandards" placeholder="请输入赔付标准" :rows="3" style="width: 400px" allowClear :maxLength="textarealth300" @keyup="btKeyUp" />
                    </a-form-model-item>

                    <a-form-model-item label="门店简介(40字内)" prop="storeIntroduction">
                        <a-textarea v-model="form.storeIntroduction" placeholder="请输入门店简介" :rows="3" style="width: 400px" allowClear :maxLength="textarealth" @keyup="btKeyUp" />
                    </a-form-model-item>

                </div>

                <div class="ml_drawer_footer">
                    <a-button @click="onClose">取消</a-button>
                    <a-button style="margin-left: 10px" type="primary" @click="onSubmit">确定</a-button>
                </div>
            </a-form-model>
        </a-drawer>
    </div>
</template>
<script>
import {
    addSupplier,
    delSupplier,
    updateSupplier,
    getSupplierByID,
    getSupplierList
} from "@/api/supplier";

import { getAllSupplierHead } from "@/api/supplierHead";

import { mapState } from "vuex";

import selectProvinceCity from "@/components/component-province-city/component-province-city";
import { validatePhone } from "@/utils/verification";
import uploadImg from "@/components/component-file/uploadImg";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

const columns = [
    // { title: "供应商编号", dataIndex: "number", align: "center" },
    { title: "供应商名称", dataIndex: "name", align: "center" },
    { title: "联系人", dataIndex: "contact", align: "center" },
    { title: "联系电话", dataIndex: "phone", align: "center" },
    { title: "服务城市", scopedSlots: { customRender: "operationContact" }, align: "center" },
    { title: "服务类型", scopedSlots: { customRender: "serviceType" }, align: "center" },
    { title: "履约金", scopedSlots: { customRender: "performancePayment" }, align: "center" },
    { title: "过户费(本市/提档)", scopedSlots: { customRender: "transferFee" }, align: "center" },
    { title: "服务费", scopedSlots: { customRender: "serviceChargeRatio" }, align: "center" },
    { title: "创建日期", dataIndex: "createTime", align: "center" },
    { title: "是否禁用", scopedSlots: { customRender: "isDisable" }, align: "center" },
    { title: "操作", scopedSlots: { customRender: "operation" }, align: "center" }
];

export default {
    components: {
        selectProvinceCity,
        uploadImg
    },
    data() {
        return {
            columns,
            data: [],
            loading: false,
            pagination: {
                pageSize: 10,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
                current: 1,
                total: 0
            },
            form: {},
            rules: {
                supplierHeadquartersNumber: [
                    { required: true, message: "请选择供应商总部" }
                ],
                name: [{ required: true, message: "请输入供应商名称" }],
                areaCode: [{ required: true, message: "请输入省市区" }],
                detailAdress: [{ required: true, message: "请输入详细地址" }],
                phone: [
                    { required: true, message: "请输入供应商联系电话" },
                    { validator: validatePhone, trigger: "change" }
                ],
                contact: [{ required: true, message: "请输入供应商联系人" }],
                serviceType: [{ required: true, message: "请选择服务类型" }],
            },
            formInline: {
                isDisable: "",
                keyWord: ""
            },
            provinceCitySelected: [],
            visibleDrawer: false,
            title: "",
            options: [],
            visibleServiceInfo: false, // 是否为示公开服务
            textarealth: 40, //长度限制
            textarealth300: 300, //长度限制
            fileList: [], //上传图片
        };
    },
    computed: {
        ...mapState({
            permissions: state => state.userStore.permissions
        })
    },
    mounted() {
        this.getSupplierList();
        getAllSupplierHead().then(res => {
            this.options = res.data;
        });
    },
    methods: {
        onChange(selectedOptions) {
            this.form.provinceCode = parseInt(selectedOptions[0].id);
            this.form.provinceName = selectedOptions[0].name;
            this.form.cityCode = parseInt(selectedOptions[1].id);
            this.form.cityName = selectedOptions[1].name;
            this.form.areaCode = parseInt(selectedOptions[2].id);
            this.form.areaName = selectedOptions[2].name;

        },
        getSupplierList() {
            const that = this;
            getSupplierList({
                isDisable: that.formInline.isDisable,
                keyWord: that.formInline.keyWord,
                pageNum: that.pagination.current,
                pageSize: that.pagination.pageSize,
                _: new Date().getTime()
            }).then(res => {
                that.data = res.data.list;
                that.data.forEach(item => {
                    if (item.createTime) {
                        item.createTime = moment(item.createTime).format("YYYY-MM-DD"); //创建日期
                    }
                });
                that.pagination.total = res.data.total;
            });
        },
        handleTableChange(pagination, filters, sorter) {
            console.log(pagination, filters, sorter);
            this.pagination = { ...pagination };
            this.getSupplierList();
        },
        onClose() {
            this.visibleDrawer = false;
            this.form = {};
        },
        addSupplier() {
            const that = this;
            that.title = "新增供应商";
            that.visibleDrawer = true;
            that.form = {
                areaCode: "",
                areaName: "",
                cityCode: "",
                cityName: "",
                contact: "",
                createUserId: "",
                detailAdress: "",
                supplierHeadquartersNumber: undefined,
                id: "",
                isDisable: false,
                formDisable: false,
                isAutoOrderReceive: false,
                formAutoOrderReceive: true,
                name: "",
                phone: "",
                provinceCode: "",
                provinceName: "",
                json: true,
                //公开服务
                serviceType: 1,
                storePhotoUrl: "",
                storeName: "",
                performancePayment: "",
                serviceChargeRatio: "",
                transferFee: "",   //本市过户费用
                transferFeeDesc: "",    //本市过户收费说明
                transferCertificatePreparation: "", //本市过户办证准备
                transferCertificatePrecondition: "", //本市过户办证前提条件
                transferOtherDesc: "",  //本市过户其他说明
                filingFee: "",  //转籍提档费用
                filingFeeDesc: "",  //转籍提档收费说明
                filingCertificatePreparation: "",   //转籍提档办证准备
                filingCertificatePrecondition: "", //转籍提档办证前提条件
                filingOtherDesc: "",    //转籍提档其他说明
                compensationStandards: "",  //赔付标准
                storeIntroduction: "",  //门店简介
            };

            that.$nextTick(() => {
                that.$refs.ruleForm.resetFields();
            });
            that.provinceCitySelected = [];
            that.fileList = [{
                text: "",
                value: "storePhotoUrl001",
                url: ""
            }];
            that.visibleServiceInfo = false;
        },
        editSupplier(e) {
            const that = this;
            that.title = "编辑供应商";
            that.visibleDrawer = true;
            that.form = {
                areaCode: "",
                areaName: "",
                cityCode: "",
                cityName: "",
                contact: "",
                createUserId: "",
                detailAdress: "",
                supplierHeadquartersNumber: undefined,
                id: "",
                isDisable: false,
                formDisable: false,
                isAutoOrderReceive: false,
                formAutoOrderReceive: true,
                name: "",
                phone: "",
                provinceCode: "",
                provinceName: "",
                json: true,
                //公开服务
                serviceType: 1,
                storePhotoUrl: "",
                storeName: "",
                performancePayment: "",
                serviceChargeRatio: "",
                transferFee: "",   //本市过户费用
                transferFeeDesc: "",    //本市过户收费说明
                transferCertificatePreparation: "", //本市过户办证准备
                transferCertificatePrecondition: "", //本市过户办证前提条件
                transferOtherDesc: "",  //本市过户其他说明
                filingFee: "",  //转籍提档费用
                filingFeeDesc: "",  //转籍提档收费说明
                filingCertificatePreparation: "",   //转籍提档办证准备
                filingCertificatePrecondition: "", //转籍提档办证前提条件
                filingOtherDesc: "",    //转籍提档其他说明
                compensationStandards: "",  //赔付标准
                storeIntroduction: "",  //门店简介
            };
            that.$nextTick(() => {
                that.$refs.ruleForm.resetFields();
            });

            that.provinceCitySelected = [];
            that.fileList = [];

            getSupplierByID(e.id).then(res => {
                if (res.data.isDisable === 1) {
                    res.data.formDisable = true;
                } else {
                    res.data.formDisable = false;
                }
                if (res.data.isAutoOrderReceive === 1) {
                    res.data.formAutoOrderReceive = true;
                } else {
                    res.data.formAutoOrderReceive = false;
                }

                delete res.data.updateTime;
                that.form = { ...res.data };

                that.provinceCitySelected.push(that.form.provinceCode);
                that.provinceCitySelected.push(that.form.cityCode);
                that.provinceCitySelected.push(parseInt(that.form.areaCode));

                if (res.data.serviceType == 2) {
                    that.visibleServiceInfo = true;
                    that.rules = {
                        supplierHeadquartersNumber: [
                            { required: true, message: "请选择供应商总部" }
                        ],
                        name: [{ required: true, message: "请输入供应商名称" }],
                        areaCode: [{ required: true, message: "请输入省市区" }],
                        detailAdress: [{ required: true, message: "请输入详细地址" }],
                        phone: [
                            { required: true, message: "请输入供应商联系电话" },
                            { validator: validatePhone, trigger: "change" }
                        ],
                        contact: [{ required: true, message: "请输入供应商联系人" }],
                        serviceType: [{ required: true, message: "请选择服务类型" }],
                        //公开服务必填校验
                        storePhotoUrl: [{ required: true, message: "请上传店铺照片" }],
                        storeName: [{ required: true, message: "请输入店铺名称" }],
                        performancePayment: [{ required: true, message: "请输入履约金" }],
                        serviceChargeRatio: [{ required: true, message: "请输入服务费比例" }],
                        transferFee: [{ required: true, message: "请输入本市过户费用" }],
                        transferFeeDesc: [{ required: true, message: "请输入本市过户收费说明" }],
                        transferCertificatePreparation: [{ required: true, message: "请输入本市过户办证准备" }],
                        transferCertificatePrecondition: [{ required: true, message: "请输入本市过户办证前提条件" }],
                        filingFee: [{ required: true, message: "请输入转籍提档费用" }],
                        filingFeeDesc: [{ required: true, message: "请输入转籍提档收费说明" }],
                        filingCertificatePreparation: [{ required: true, message: "请输入转籍提档办证准备" }],
                        filingCertificatePrecondition: [{ required: true, message: "请输入转籍提档办证前提条件" }],
                        compensationStandards: [{ required: true, message: "请输入赔付标准" }],
                        storeIntroduction: [{ required: true, message: "请输入门店简介" }],
                    };
                } else {
                    that.visibleServiceInfo = false;
                }

                if (res.data.storePhotoUrl) {
                    that.fileList.push({
                        text: "",
                        value: "storePhotoUrl001",
                        url: res.data.storePhotoUrl
                    });
                } else {
                    that.fileList = [{
                        text: "",
                        value: "storePhotoUrl001",
                        url: ""
                    }]
                }

            });
        },
        delSupplier(e) {
            const that = this;
            that.$confirm({
                title: "是否确认删除该供应商?",
                okType: "danger",
                okText: "确定",
                cancelText: "取消",
                centered: true,
                onOk() {
                    delSupplier(e.id).then(() => {
                        that.$message.success({
                            content: "删除成功！",
                            duration: 1,
                            onClose: () => {
                                that.getSupplierList();
                            }
                        });
                    });
                },
                onCancel() {
                    console.log("Cancel");
                }
            });
        },
        onSubmit() {
            const that = this;
            that.$refs.ruleForm.validate(valid => {
                if (valid) {
                    that.form["json"] = true;
                    if (that.form.formDisable) {
                        that.form.isDisable = 1;
                    } else {
                        that.form.isDisable = 0;
                    }
                    if (that.form.formAutoOrderReceive) {
                        that.form.isAutoOrderReceive = 1;
                    } else {
                        that.form.isAutoOrderReceive = 0;
                    }
                    if (that.form.id) {
                        updateSupplier(that.form).then(() => {
                            that.$message.success({
                                content: "修改成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visibleDrawer = false;
                                    that.getSupplierList();
                                }
                            });
                        });
                    } else {
                        addSupplier(that.form).then(() => {
                            that.$message.success({
                                content: "添加成功！",
                                duration: 1,
                                onClose: () => {
                                    that.visibleDrawer = false;
                                    that.getSupplierList();
                                }
                            });
                        });
                    }
                }
            });
        },
        onChangeServiceType() {
            //服务类型
            const that = this;
            if (that.form.serviceType == 2) { //公开服务
                that.visibleServiceInfo = true;
                that.rules = {
                    supplierHeadquartersNumber: [
                        { required: true, message: "请选择供应商总部" }
                    ],
                    name: [{ required: true, message: "请输入供应商名称" }],
                    areaCode: [{ required: true, message: "请输入省市区" }],
                    detailAdress: [{ required: true, message: "请输入详细地址" }],
                    phone: [
                        { required: true, message: "请输入供应商联系电话" },
                        { validator: validatePhone, trigger: "change" }
                    ],
                    contact: [{ required: true, message: "请输入供应商联系人" }],
                    serviceType: [{ required: true, message: "请选择服务类型" }],
                    //公开服务必填校验
                    storePhotoUrl: [{ required: true, message: "请上传店铺照片" }],
                    storeName: [{ required: true, message: "请输入店铺名称" }],
                    performancePayment: [{ required: true, message: "请输入履约金" }],
                    serviceChargeRatio: [{ required: true, message: "请输入服务费比例" }],
                    transferFee: [{ required: true, message: "请输入本市过户费用" }],
                    transferFeeDesc: [{ required: true, message: "请输入本市过户收费说明" }],
                    transferCertificatePreparation: [{ required: true, message: "请输入本市过户办证准备" }],
                    transferCertificatePrecondition: [{ required: true, message: "请输入本市过户办证前提条件" }],
                    filingFee: [{ required: true, message: "请输入转籍提档费用" }],
                    filingFeeDesc: [{ required: true, message: "请输入转籍提档收费说明" }],
                    filingCertificatePreparation: [{ required: true, message: "请输入转籍提档办证准备" }],
                    filingCertificatePrecondition: [{ required: true, message: "请输入转籍提档办证前提条件" }],
                    compensationStandards: [{ required: true, message: "请输入赔付标准" }],
                    storeIntroduction: [{ required: true, message: "请输入门店简介" }],
                };
            } else {
                that.visibleServiceInfo = false;
            }
        },
        uploadImgReturn(val) {
            // 上传组件返回数据
            const that = this;
            that.fileList = val;
            that.fileList.forEach(element => {
                switch (element.value) {
                    case "storePhotoUrl001":
                        that.form.storePhotoUrl = element.url;
                        break;
                }
            });
        },
        btKeyUp(e) {
            e.target.value = e.target.value.replace(/[`~!@#$^&*+-/<><//>?]/g, "").replace(/\s/g, "");
        },
    }
};
</script>
<style lang="scss" scoped>
.addform .f_wid {
    width: 260px;
}
.addform .ant-form-item {
    margin-top: 15px;
}
</style>