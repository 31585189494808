<template>
    <div>
        <a-row>
            <a-col :span="8" v-for="(item, index) in imgList" :key="index">
                <a-upload :customRequest="customRequest" :name="item.value" list-type="picture-card" :show-upload-list="false" :before-upload="beforeUpload" @preview="handlePreview">
                    <div v-if="item.url">
                        <img v-if="item.url" :src="item.url" class="ml_img_upload" />
                        <div class="ant-upload-text">
                            {{ item.text }}
                        </div>
                    </div>
                    <div v-else>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                            {{ item.text }}
                        </div>
                    </div>
                </a-upload>
            </a-col>
        </a-row>
    </div>
</template>
<script>
const token = localStorage.getItem("cbz_token");
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

import { upLoadFile } from "@/api/fileUpload";

export default {
    props: {
        imgConfig: {
            type: Array,
            value: {}
        }
    },
    watch: {
        imgConfig(n, o) {
            console.log(n, o);
            this.imgList = n;
        }
    },
    data() {
        return {
            headers: { token: token },
            previewVisible: false,
            previewImage: "",
            imgList: [], // 图片信息
            loading: false,
        }
    },
    mounted() {
        this.imgList = this.imgConfig;
    },
    methods: {
        customRequest(file) {
            const that = this;
            var formData = new FormData();
            formData.append("file", file.file);
            formData.append("success_action_status", 201); //成功后返回的操作码
            file.onProgress();
            upLoadFile({
                file: file.file,
                fileCode: file.filename,
                businessid: ""
            })
                .then(res => {
                    that.imgList.forEach(element => {
                        if (element.value == file.filename) {
                            that.$nextTick(() => {
                                element.url = res.data;
                                // 向父级传值
                                this.$emit("uploadRetrun", that.imgList);
                            });
                        }
                    });
                })
                .catch(() => {
                    file.onError(); //上传失败
                });
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        beforeUpload(file) {
            const isLtSize = file.size / 1024 < 10 * 1024;

            if (!isLtSize) {
                this.$message.error("上传文件不能超过10 MB!");
                return false;
            }
            return isLtSize;
        }
    }
}
</script>
<style lang="scss">
.ml_img_upload {
    width: 100%;
    height: 68px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
</style>
