<!--
 * @Descripttion : 省市区选择器
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-07 17:41:37
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-08 09:49:30
-->
<template>
  <a-cascader :options="options" v-model="defaultProvinceCity" :placeholder="placeholder" :fieldNames="{ label: 'name', value: 'id', children: 'values' }" @change="onChange" />
</template>

<script>
import { GetProvinceAndCityAndAreaList } from "@/api/saasV5";

export default {
  props: {
    placeholder: {
      type: String,
      default: "请选择地址"
    },
    selectedProvinceCity: {
      type: Array,
      default: null
    }
  },
  watch: {
    selectedProvinceCity(o, n) {
      console.log(o, n);
      this.defaultProvinceCity = this.selectedProvinceCity;
    }
  },
  data() {
    return {
      options: [],
      defaultProvinceCity: null
    };
  },
  mounted() {
    GetProvinceAndCityAndAreaList({
      json: true
    }).then(res => {
      this.$nextTick(() => {
        this.options = res.data;
      });
    });
  },
  methods: {
    onChange(value, selectedOptions) {
      // 选中数据返回给父级
      console.log(value, selectedOptions);
      if (selectedOptions) {
        this.$emit("change", selectedOptions);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>