/*
 * @Descripttion : 供应商总部
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-07 17:08:35
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-07 17:15:41
 */
import request from './request'

// 新增供应商总部
export function addSupplierHead(data) {
	return request.post('supplierHeadquarters', data)
}

// 修改供应商总部
export function updateSupplierHead(data) {
	return request.put('supplierHeadquarters', data)
}

// 根据ID获取供应商总部
export function getSupplierHeadByID(id) {
	return request.fetch('supplierHeadquarters/' + id)
}

// 删除供应商总部
export function deleteSupplierHead(id) {
	return request.delete('supplierHeadquarters/' + id)
}

// 获取全部供应商总部
export function getAllSupplierHead(data) {
	return request.fetch('supplierHeadquarters/all', data)
}

// 获取供应商总部列表
export function getSupplierHeadList(data) {
	return request.fetch('supplierHeadquarters/supplierHeadquartList', data)
}
